const sponsorInfoUrl = new URL(
  '../assets/shooting-for-change-sponsorship-info.png',
  import.meta.url
);

export default function Event({summary, depth, contextHeader, largeHeader, mainImage, moreoverHeader, moreoverBody, options, children, contextColor="text-purple-400", useTexture=true, smallImage=false}) {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 pt-8 pb-2 sm:pb-8 lg:overflow-visible lg:px-0">
      <div id="hackathon" className={`mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-6 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:items-start lg:gap-y-8`}>
        <div className={`lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8`}>
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className={`text-base font-semibold leading-7 ${contextColor}`}>{contextHeader}</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{largeHeader}</h1>
              <p className={`${summary ? '': 'hidden'} mt-6 text-xl leading-8 text-gray-700`}>
                {summary}
              </p>
            </div>
        <a download="shooting-for-change-sponsorship-info" className="rw-partner mt-4 font-semibold text-red block" href="mailto:pres@bitnash.org">Contact the Nashville Chapter of the Black In Technology Foundation about sponsorship opportunities.</a>
          </div>
        </div>
        <a href={mainImage} download="shooting-for-change-flyer" className={`-ml-0 p-0 lg:sticky lg:top-4 ${smallImage ? 'lg:col-start-3': 'lg:col-start-2 -mt-12' } lg:row-span-2 lg:row-start-1 lg:overflow-hidden rw-partner`}>
          <img
            className="rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem] sm:mx-auto"
            src={mainImage}
            alt=""
          />
        </a>
        <div className={`lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8`}>
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className={` ${depth ? '': 'hidden'}`}>
                {depth}
              </p>
              <ul role="list" className="list-inside list-disc mt-8 space-y-6 text-gray-600">
                {options && options.map((e, i) =>
                <li key={i} className="gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">{e.name}</strong> {e.description}
                  </span>
                </li>
                )}
                {children}
              </ul>
              <h2 className={`mt-16 text-2xl font-bold tracking-tight text-gray-900 ${moreoverHeader ? '' : 'hidden'}`}>{moreoverHeader}</h2>
              <p className={`mt-6 ${moreoverBody ? '' : 'hidden'}`}>
                {moreoverBody}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
